<template>
  <form class="pb-2" action="." method="POST" @submit.prevent="submit">
    <!-- New password field -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("edit_password.type_new_password") }}</label>
      <div class="control is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
        <input class="input is-simple" :type="newPasswordInputType" :placeholder="$t('auth.password')" v-model="newPassword" />
        <div class="password-visibility-button" @click="toggleNewPasswordVisibility">
          <visibility-off-icon class="svg-icon has-fill-primary" v-if="newPasswordVisible"></visibility-off-icon>
          <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
        </div>
      </div>
      <template v-if="submitted">
        <p v-if="!$v.newPassword.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
        <p v-if="!$v.newPassword.minLength" class="help is-danger">{{ $t("edit_password.invalid_password") }}</p>
      </template>
    </div>

    <!-- New password confirmation field -->
    <div class="field">
      <label class="label has-text-primary">{{ $t("edit_password.type_new_password_again") }}</label>
      <div class="control is-flex is-flex-direction-row is-align-items-center is-justify-content-center">
        <input
          class="input is-simple"
          :type="newPasswordConfirmationInputType"
          :placeholder="$t('auth.password')"
          v-model="newPasswordConfirmation"
        />
        <div class="password-visibility-button" @click="toggleNewPasswordConfirmationVisibility">
          <visibility-off-icon class="svg-icon has-fill-primary" v-if="newPasswordConfirmationVisible"></visibility-off-icon>
          <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
        </div>
      </div>
      <template v-if="submitted">
        <p v-if="!$v.newPasswordConfirmation.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
        <p v-if="!$v.newPasswordConfirmation.sameAsNewPassword" class="help is-danger">{{ $t("edit_password.password_doesnt_match") }}</p>
      </template>
    </div>

    <!-- Api error -->
    <div v-if="error" class="mt-2 is-size-4 has-text-weight-bold has-text-danger">
      <div class="has-text-centered">
        {{ error | pluckError }}
      </div>
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          {{ $t("auth.set_password_button") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import VisibilityIcon from "@/assets/auth/icon_visibility.svg";
import VisibilityOffIcon from "@/assets/auth/icon_visibility_off.svg";

export default {
  name: "EditPasswordForm",

  props: ["showProgress", "error"],

  components: { VisibilityOffIcon, VisibilityIcon },

  data() {
    return {
      submitted: false,
      newPassword: "",
      newPasswordConfirmation: "",
      newPasswordVisible: false,
      newPasswordConfirmationVisible: false,
    };
  },

  validations: {
    newPassword: { required, minLength: minLength(7) },
    newPasswordConfirmation: {
      required,
      sameAsNewPassword: sameAs("newPassword"),
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const formData = this.$data;
        this.$emit("submit", formData);
      }
    },

    toggleNewPasswordVisibility() {
      this.newPasswordVisible = !this.newPasswordVisible;
    },

    toggleNewPasswordConfirmationVisibility() {
      this.newPasswordConfirmationVisible = !this.newPasswordConfirmationVisible;
    },
  },

  computed: {
    newPasswordInputType() {
      if (this.newPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    },

    newPasswordConfirmationInputType() {
      if (this.newPasswordConfirmationVisible) {
        return "text";
      } else {
        return "password";
      }
    },
  },
};
</script>

<style scoped></style>
